@import "./normalize";

// phones
@media only screen and (max-width: 700px) and (min-height: 550px), (max-width: 550px) {
   body {
     touch-action: none;
   }
  .info-hotspot {
    .info-hotspot-header {
      .info-hotspot-title-wrapper {
        .info-hotspot-title {
          padding: 0 10px 2px 10px;
        }
      }
    }
  }

  .title-bar-inner {
    .pc-logo {
      max-width: 100px;
    }
  }

  #tooltip-LH {
    min-width: 80px;
  }

  .info-hotspot-modal {
    padding: 0;
    margin: 0;

    .info-hotspot-close-wrapper {
      bottom: 0.35rem;
      right: -1rem;
    }

    .info-hotspot-content:not(.singlePaneContent):not(.fullContent) {
      max-width: 350px;
      max-height: 100%;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column-reverse;

      .product-txt-container,
      .carousel_container {
        width: 100%;
        height: 100%;
      }

      .product-txt-container > .product-txt-wrapper{
        padding: unset;
        padding: 2.5% 10%;
      }

       .carousel-images {
        #product-image:not(.iframe_container__video) {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 100%;
          height: 100%;
          padding: 2rem;

          & > * {
            object-fit: contain;
            min-width: 100%;
            height: 100%;
          }
        }
        }

      .carousel_container {
        border-bottom: 1px solid var(--secondary-blk);
        .carousel-pagination {
          bottom: 1rem;
        }
      }

      .product-txt-container {
        padding: 1rem 0;
          &::-webkit-scrollbar {
        width: 5px;
        }
        .product-txt-wrapper {
          padding: unset;
          padding: 0 2rem;

          ul {
            font-size: 0.7rem;
          }


          .recipes {
            .recipes-list {
              grid-template-columns: repeat(2, minmax(120px, 1fr));
              .recipe-title {
                font-size: 0.5rem;
                padding: 0 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}

/* medium */
@media only screen and (max-width: 1024px) {
  .desktop{
  .info-hotspot-modal:not(.pdf) {
    padding: unset;
    margin: unset;

    .info-hotspot-content {
      flex-direction: column-reverse;
      max-height: 800px;
      max-width: 600px;
      height: auto;

      .carousel_container {
        border-bottom: 1px solid var(--secondary-blk);
      }
      .carousel_container, .carousel, .product-txt-container {
        width: 100%;
        height: 100%;
      }

      .carousel-images {
        #product-image {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 100%;
          height: 100%;
          padding: 2rem;

          & > * {
            object-fit: contain;
            min-width: 100%;
            height: 100%;
          }
        }
        }
      }
    }
  }
}

  .info-hotspot {
    .info-hotspot-title-wrapper {
      .info-hotspot-title {
        line-height: 0.8rem;
        width: 300px;
      }
    }

    // .info-hotspot-header {
    //   width: 40px;
    //   height: 40px;
    //   margin: 0;

    //   .info-hotspot-icon {
    //     width: 30px;
    //     height: 30px;
    //     margin: 3px;
    //   }
    // }
  }

@media screen and (min-width: 1024px) {
  .title-bar-inner {
    .pc-logo {
      max-width: 130px;
    }
  }
  .desktop .info-hotspot .info-hotspot-header:hover {
    width: 170px;
    z-index: 10;
  }

  .info-hotspot {
    .info-hotspot-header {
      .info-hotspot-title-wrapper {
        .info-hotspot-title {
          font-size: .9rem;
          line-height: 1rem;
          padding: 0 20px 2px 20px;
        }
      }
    }
  }

  .info-hotspot-modal {
    .info-hotspot-header {
      max-width: 100%;
      .info-hotspot-title-wrapper {
        .info-hotspot-title {
          font-size: 2rem;
        }
      }
      .info-hotspot-close-wrapper {
        bottom: 0.8rem;
        right: 3rem;
        img {
          width: 25px;
        }
      }
    }

    .info-hotspot-content:not(.singlePaneContent) {
      max-width: 85%;
      max-height: 80%;

      .product-txt-container {
        align-self: center;
        padding: 0 1rem;

        .product-txt-wrapper {
          .product-txt-subhead {
            font-size: 3.25em;
          }

          .product-txt {
            font-size: $base-font-size;
            line-height: 1.2rem;

            ul {
              .title {
                margin-top: 2rem;
                margin-bottom: 1rem;
                font-size: 1.5rem;
                line-height: unset;
                line-height: 1.6rem;
              }
              li {
                padding-top: 1em;
              }
            }
          }

          .recipes {

            .recipes-list {
              justify-content: flex-start;

              li {
                .recipe-link {
                  .recipe-title {
                    font-size: 0.8rem;
                    display: flex;
                    height: 50px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .carousel_container {
      .carousel-nav {
        max-width: 90%;
        .carousel-button {
          max-width: 40px;
          max-height: 40px;
        }
      }
      .carousel-images {
        #product-image {
          padding: 4rem;
        }
      }
    }
  }

}

