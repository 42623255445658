@media only screen and (width <= 700px) and (height >= 550px), (width <= 550px) {
  body {
    touch-action: none;
  }

  .info-hotspot .info-hotspot-header .info-hotspot-title-wrapper .info-hotspot-title {
    padding: 0 10px 2px;
  }

  .title-bar-inner .pc-logo {
    max-width: 100px;
  }

  #tooltip-LH {
    min-width: 80px;
  }

  .info-hotspot-modal {
    margin: 0;
    padding: 0;
  }

  .info-hotspot-modal .info-hotspot-close-wrapper {
    bottom: .35rem;
    right: -1rem;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) {
    flex-direction: column-reverse;
    width: 100%;
    max-width: 350px;
    height: 100%;
    max-height: 100%;
    display: flex;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) .product-txt-container, .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) .carousel_container {
    width: 100%;
    height: 100%;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) .product-txt-container > .product-txt-wrapper {
    padding: unset;
    padding: 2.5% 10%;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) .carousel-images #product-image:not(.iframe_container__video) {
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) .carousel-images #product-image:not(.iframe_container__video) > * {
    -o-object-fit: contain;
    object-fit: contain;
    min-width: 100%;
    height: 100%;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) .carousel_container {
    border-bottom: 1px solid var(--secondary-blk);
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) .carousel_container .carousel-pagination {
    bottom: 1rem;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) .product-txt-container {
    padding: 1rem 0;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) .product-txt-container::-webkit-scrollbar {
    width: 5px;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) .product-txt-container .product-txt-wrapper {
    padding: unset;
    padding: 0 2rem;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) .product-txt-container .product-txt-wrapper ul {
    font-size: .7rem;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) .product-txt-container .product-txt-wrapper .recipes .recipes-list {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent):not(.fullContent) .product-txt-container .product-txt-wrapper .recipes .recipes-list .recipe-title {
    padding: 0 .5rem;
    font-size: .5rem;
  }
}

@media only screen and (width <= 1024px) {
  .desktop .info-hotspot-modal:not(.pdf) {
    padding: unset;
    margin: unset;
  }

  .desktop .info-hotspot-modal:not(.pdf) .info-hotspot-content {
    flex-direction: column-reverse;
    max-width: 600px;
    height: auto;
    max-height: 800px;
  }

  .desktop .info-hotspot-modal:not(.pdf) .info-hotspot-content .carousel_container {
    border-bottom: 1px solid var(--secondary-blk);
  }

  .desktop .info-hotspot-modal:not(.pdf) .info-hotspot-content .carousel_container, .desktop .info-hotspot-modal:not(.pdf) .info-hotspot-content .carousel, .desktop .info-hotspot-modal:not(.pdf) .info-hotspot-content .product-txt-container {
    width: 100%;
    height: 100%;
  }

  .desktop .info-hotspot-modal:not(.pdf) .info-hotspot-content .carousel-images #product-image {
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
  }

  .desktop .info-hotspot-modal:not(.pdf) .info-hotspot-content .carousel-images #product-image > * {
    -o-object-fit: contain;
    object-fit: contain;
    min-width: 100%;
    height: 100%;
  }
}

.info-hotspot .info-hotspot-title-wrapper .info-hotspot-title {
  width: 300px;
  line-height: .8rem;
}

@media screen and (width >= 1024px) {
  .title-bar-inner .pc-logo {
    max-width: 130px;
  }

  .desktop .info-hotspot .info-hotspot-header:hover {
    z-index: 10;
    width: 170px;
  }

  .info-hotspot .info-hotspot-header .info-hotspot-title-wrapper .info-hotspot-title {
    padding: 0 20px 2px;
    font-size: .9rem;
    line-height: 1rem;
  }

  .info-hotspot-modal .info-hotspot-header {
    max-width: 100%;
  }

  .info-hotspot-modal .info-hotspot-header .info-hotspot-title-wrapper .info-hotspot-title {
    font-size: 2rem;
  }

  .info-hotspot-modal .info-hotspot-header .info-hotspot-close-wrapper {
    bottom: .8rem;
    right: 3rem;
  }

  .info-hotspot-modal .info-hotspot-header .info-hotspot-close-wrapper img {
    width: 25px;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent) {
    max-width: 85%;
    max-height: 80%;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent) .product-txt-container {
    align-self: center;
    padding: 0 1rem;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent) .product-txt-container .product-txt-wrapper .product-txt-subhead {
    font-size: 3.25em;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent) .product-txt-container .product-txt-wrapper .product-txt {
    font-size: 14px;
    line-height: 1.2rem;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent) .product-txt-container .product-txt-wrapper .product-txt ul .title {
    font-size: 1.5rem;
    line-height: unset;
    margin-top: 2rem;
    margin-bottom: 1rem;
    line-height: 1.6rem;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent) .product-txt-container .product-txt-wrapper .product-txt ul li {
    padding-top: 1em;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent) .product-txt-container .product-txt-wrapper .recipes .recipes-list {
    justify-content: flex-start;
  }

  .info-hotspot-modal .info-hotspot-content:not(.singlePaneContent) .product-txt-container .product-txt-wrapper .recipes .recipes-list li .recipe-link .recipe-title {
    height: 50px;
    font-size: .8rem;
    display: flex;
  }

  .info-hotspot-modal .carousel_container .carousel-nav {
    max-width: 90%;
  }

  .info-hotspot-modal .carousel_container .carousel-nav .carousel-button {
    max-width: 40px;
    max-height: 40px;
  }

  .info-hotspot-modal .carousel_container .carousel-images #product-image {
    padding: 4rem;
  }
}
/*# sourceMappingURL=index.5975a5f1.css.map */
